<template>
  <v-card
    elevation="0"
    :outlined="isProvider"
    :style="!isProvider && 'background:transparent'"
  >
    <v-row no-gutters align="center">
      <v-col cols="12" sm="12" md="3" lg="3">
        <div class="d-flex justify-center">
          <v-img v-if="image" height="186" width="248" :src="image" />
          <v-icon v-else size="200"> mdi-image-outline </v-icon>
        </div>
        <div
          v-if="isProvider"
          class="secondary font-museo-sans white--text d-flex justify-center"
        >
          Período
        </div>
      </v-col>
      <v-col
        @click="$emit('goToInfo', convenio.id)"
        cols="12"
        sm="12"
        md="9"
        lg="9"
        :class="isProvider && 'customHover'"
      >
        <p class="text-h6 font-museo-sans secondary--text ml-4">
          {{ convenio.nombre }}
        </p>
        <p class="font-museo-sans primary--text ml-4">
          Fecha de publicación:
          {{
            convenio.fecha_publicacion &&
            moment(convenio.fecha_publicacion).format("DD/MM/YYYY")
          }}
        </p>
        <p class="font-museo-sans primary--text ml-4">
          Período
          {{
            currentPhase.fecha_inicio &&
            moment(currentPhase.fecha_inicio).format("DD/MM/YYYY")
          }}
          -
          {{
            currentPhase.fecha_inicio &&
            moment(currentPhase.fecha_fin).format("DD/MM/YYYY")
          }}
        </p>
        <p v-if="!isProvider" class="font-museo-sans primary--text ml-4">
          Estado: {{ currentPhase?.nombre }}
        </p>
        <p class="font-museo-sans primary--text ml-4">
          Código: {{ convenio.correlativo }}
        </p>
        <p v-if="isProvider" class="font-museo-sans primary--text ml-4">
          Vigencia: {{ periodDate }}
        </p>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import moment from "moment-timezone";
export default {
  name: "DetalleConvenioComponent",
  props: {
    convenio: {
      type: Object,
      default: () => {},
    },
    isProvider: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    convenio() {
      this.getConvenioImage();
    },
  },
  data: () => ({
    image: null,
  }),
  computed: {
    periodDate() {
      if (this.convenio?.fecha_inicio && this.convenio?.fecha_fin) {
        return `${moment(this.convenio.fecha_inicio).format(
          "DD/MM/YYYY"
        )} - ${moment(this.convenio.fecha_fin).format("DD/MM/YYYY")}`;
      } else return "";
    },
    currentPhase() {
      if (this.convenio?.fase?.length > 0) {
        return this.convenio.fase[0];
      } else return {};
    },
    convenioEtapa() {
      return this.convenio?.fase?.length > 0;
    },
    currentState() {
      switch (this.convenio.id_estado) {
        case 1:
          return "Convenio vigente";
        case 5:
          return "Convenio cerrado";
        case 6:
          return "Convenio finalizado";
        default:
          return "Convenio sin etapa vigente";
      }
    },
  },
  methods: {
    async getConvenioImage() {
      let response = await this.services.AgreementMarco.getConvenioPhoto(
        this.convenio.id
      );
      if (response?.status === 200) {
        const urlCreator = window.URL || window.webkitURL;
        this.image = urlCreator.createObjectURL(response?.data);
      }
    },
  },
  created() {
    if (this.convenio.id) {
      this.getConvenioImage();
    }
  },
};
</script>
<style scoped>
.customHover:hover {
  background: #BFCFF4;
  cursor: pointer;
}
</style>
