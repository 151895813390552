<template>
  <section>
    <div>
      <v-breadcrumbs large :items="breadcrumbsItems"></v-breadcrumbs>
    </div>
    <v-row>
      <v-col cols="12" sm="12" md="8" lg="8">
        <DetalleConvenio :convenio="agreement_detail" />
      </v-col>
    </v-row>
    <v-tabs background-color="transparent">
      <v-tabs-slider color="secondary"></v-tabs-slider>
      <v-tab>
        <v-icon class="mx-2">mdi-clipboard-text</v-icon> Etapa Actual
      </v-tab>
      <v-tab-item>
        <v-row align="center">
          <v-col cols="6">
            <p class="mt-4 text-h6 font-weight-bold secondary--text">
              {{ currentPhase?.nombre || "Ejecución" }}
            </p>
          </v-col>
          <v-col cols="6" v-if="Object.keys(currentPhase).length">
            <v-row>
              <v-col cols="6">
                <p>
                  Fecha de Inicio:
                  {{ moment(currentPhase?.fecha_inicio).format("DD/MM/YYYY") }}
                </p>
              </v-col>
              <v-col cols="6">
                <p>
                  Fecha de Fin:
                  {{ moment(currentPhase?.fecha_fin).format("DD/MM/YYYY") }}
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p class="mb-0">Listado de proveedores ofertantes</p>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="filteredHeader"
              :items="offer_providers"
              hide-default-footer
              no-data-text="No hay datos disponibles"
              :items-per-page="filters.per_page"
              :page.sync="filters.page"
            >
              <template #[`item.id`]="{ item }">
                {{
                  firstElement(
                    offer_providers
                      .map((col) => col.id)
                      .reverse()
                      .indexOf(item.id) + 1
                  )
                }}
              </template>
              <template v-slot:[`item.nombre_estado`]="{ item }">
                <v-chip
                  label
                  :color="item.offer.color_estado"
                  :text-color="
                    item.offer.id_estado == 3 || item.offer.id_estado == 4
                      ? 'white'
                      : 'black'
                  "
                >
                  {{ item.offer.nombre_estado }}
                </v-chip>
              </template>

              <template v-slot:[`item.valoracion`]="{ item }">
                <v-rating
                  readonly
                  half-increments
                  color="secondary"
                  background-color="secondary"
                  length="5"
                  :value="Number.parseFloat(item.calificacion.calificacion)"
                />
              </template>

              <template v-slot:[`item.acciones`]="{ item }">
                <template v-if="readOnlyOffers">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        large
                        color="primary"
                        @click="verOferta(item)"
                      >
                        mdi-eye-outline
                      </v-icon>
                    </template>
                    <span> Ver </span>
                  </v-tooltip>
                </template>
                <template v-else>
                  <v-tooltip
                    right
                    v-if="
                      item.offer.id_estado == 1 || item.offer.id_estado == 2
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        large
                        color="primary"
                        @click="verOferta(item.offer)"
                      >
                        mdi-clipboard-check-outline
                      </v-icon>
                    </template>
                    <span> Ver </span>
                  </v-tooltip>
                  <v-tooltip
                    right
                    v-if="
                      item.offer.id_estado == 1 || item.offer.id_estado == 2
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        large
                        color="primary"
                        @click="setToReject(item.offer)"
                      >
                        mdi-block-helper
                      </v-icon>
                    </template>
                    <span> Rechazar </span>
                  </v-tooltip>
                  <v-tooltip
                    right
                    v-if="
                      item.offer.id_estado == 3 || item.offer.id_estado == 4
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        large
                        color="primary"
                        @click="setToRevert(item.offer)"
                      >
                        mdi-refresh
                      </v-icon>
                    </template>
                    <span> Revertir </span>
                  </v-tooltip>
                </template>
              </template>
              <template v-slot:[`footer`]>
                <app-pagination
                  :totalElements="filters.total_rows"
                  @cantidadPage="paginate"
                  @changePage="paginate"
                />
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <!-- botones atrás guardar selección-->

        <v-row>
          <v-col cols="8" sm="3" md="4" lg="4">
            <v-btn class="ma-2" outlined @click="regresa()"> Atrás </v-btn>

            <!-- <v-btn
              v-if="!readOnlyOffers"
              class="ma-2"
              color="secondary"
              dark
              @click="Agregarobs()"
            >
              Guardar selección
            </v-btn> -->
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>

    <!-- Modal revertir oferta -->
    <v-dialog v-model="dialogRevertir" width="600" close persistent>
      <v-card class="pa-2">
        <v-card-title
          class="text-h6 primary--text justify-center font-weight-bold"
        >
          ¿Desea revertir los cambios realizados?
        </v-card-title>
        <br />
        <v-card-text
          class="primary--text d-flex flex-column text-center justify-center"
        >
          De confirmar la siguiente acción los cambios no serán reversibles
          <br />
          <br />
          <v-textarea
            :error-messages="rejectDescriptionErrors"
            @input="$v.rejectDescription.$touch()"
            label="Descripción*"
            outlined
            v-model="rejectDescription"
          >
          </v-textarea>
        </v-card-text>
        <v-spacer></v-spacer>
        <br />
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="ma-1"
            outlined
            @click="(dialogRevertir = false), resetJustification()"
          >
            Cancelar
          </v-btn>
          <v-btn class="ma-1" color="secondary" dark @click="revertOffer()">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal rechazar oferta -->
    <v-dialog v-model="dialogRechazar" width="600" close persistent>
      <v-card class="pa-2">
        <v-card-title
          class="text-h6 primary--text justify-center font-weight-bold"
        >
          ¿Desea rechazar la aplicación del proveedor?
        </v-card-title>

        <br />
        <v-card-text
          class="primary--text d-flex flex-column justify-center text-center"
        >
          De confirmar la siguiente acción los cambios no serán reversibles
          <br />
          <br />
          <v-textarea
            :error-messages="rejectDescriptionErrors"
            @input="$v.rejectDescription.$touch()"
            label="Descripción*"
            outlined
            v-model="rejectDescription"
          >
          </v-textarea>
        </v-card-text>
        <br />
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="ma-1"
            outlined
            @click="(dialogRechazar = false), resetJustification()"
          >
            Cancelar
          </v-btn>
          <v-btn class="ma-1" color="secondary" dark @click="rejectOffer()">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import DetalleConvenio from "../../components/DetalleConvenioComponent.vue";
import AppPagination from "@/components/AppPaginationComponent.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import {
  required,
  maxLength,
  minLength,
  helpers,
} from "vuelidate/lib/validators";

const alphaNum = helpers.regex("alphaNum", /^[a-zA-Z0-9-#,. ]*$/);
export default {
  name: "evaluacionOfertasView",
  components: {
    DetalleConvenio,
    AppPagination,
  },
  validations: {
    rejectDescription: {
      required,
      alphaNum,
      minLength: minLength(10),
      maxLength: maxLength(500),
    },
  },
  data: () => ({
    dialogRevertir: false,
    dialogRechazar: false,
    rejectDescription: null,
    offer_providers: [],
    itemSelected: null,
    headers: [
      {
        text: "Correlativo",
        align: "center",
        sortable: false,
        value: "id",
      },
      {
        text: "Proveedor",
        align: "center",
        sortable: false,
        value: "offer.nombre_comercial",
      },
      {
        text: "Valoración",
        align: "center",
        sortable: false,
        value: "valoracion",
      },
      {
        text: "Estado",
        align: "center",
        sortable: false,
        value: "nombre_estado",
      },
      { text: "Acciones", align: "center", sortable: false, value: "acciones" },
    ],
    filters: {
      page: 1,
      per_page: 10,
      total_rows: null,
    },
  }),
  computed: {
    ...mapState("convenioMarcoAdmin", [
      "agreement_detail",
      "breadcrumbsItems",
      "readOnlyOffers",
    ]),
    filteredHeader() {
      if (this.readOnlyOffers) {
        return this.headers.filter((target) => target.value !== "status");
      }
      return this.headers;
    },
    currentPhase() {
      if (this.agreement_detail?.fase?.length > 0) {
        return this.agreement_detail.fase[0];
      } else return {};
    },
    rejectDescriptionErrors() {
      const errors = [];
      if (!this.$v.rejectDescription.$dirty) return errors;
      !this.$v.rejectDescription.required &&
        errors.push("El campo Descripción es obligatorio");
      !this.$v.rejectDescription.minLength &&
        errors.push("Mínimo de caracteres 10");
      !this.$v.rejectDescription.maxLength &&
        errors.push("Máximo de caracteres 500");
      !this.$v.rejectDescription.alphaNum &&
        errors.push(
          "El campo Descripción solo admite caracteres alfanuméricos"
        );
      return errors;
    },
  },
  methods: {
    ...mapActions("convenioMarcoAdmin", ["getReviewOffers"]),
    ...mapMutations("convenioMarcoAdmin", [
      "setTempProviderId",
      "setReadOnlyOffers",
    ]),
    regresa() {
      this.$router.push("/convenio-marco-list");
      this.setReadOnlyOffers(false);
    },
    resetJustification() {
      this.rejectDescription = null;
      this.itemSelected = null;
      this.$v.rejectDescription.$reset();
    },

    setToReject(item) {
      this.itemSelected = item.id;
      this.dialogRechazar = true;
    },

    async rejectOffer() {
      this.$v.rejectDescription.$touch();

      if (!this.$v.rejectDescription.$invalid) {
        let response = await this.services.AgreementMarco.rejectAgreement(
          this.itemSelected,
          { justificacion: this.rejectDescription }
        );
        if (response?.status == 200 || response?.status == 204) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "La aplicación ha sido rechazada",
          });
          this.getProviderOffers();
          this.resetJustification();
          this.dialogRechazar = false;
        }
      }
    },

    async verOferta(item) {
      await this.getReviewOffers({
        id_agreement: this.agreement_detail.id,
        id_provider: item?.offer?.id_proveedor || item.id_proveedor,
      });
      this.setTempProviderId(item?.offer?.id_proveedor || item.id_proveedor);
      this.$router.push("/evaluacion-proveedor");
    },
    setToRevert(item) {
      this.itemSelected = item.id;
      this.dialogRevertir = true;
    },
    async revertOffer() {
      this.$v.rejectDescription.$touch();

      if (!this.$v.rejectDescription.$invalid) {
        let response = await this.services.AgreementMarco.revertAgreement(
          this.itemSelected,
          { justificacion: this.rejectDescription }
        );
        if (response?.status == 200 || response?.status == 204) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: "Los cambios han sido revertidos",
          });
          this.getProviderOffers();
          this.resetJustification();
          this.dialogRevertir = false;
        }
      }
    },
    paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      this.filters.page = pagina;
      this.filters.per_page = cantidad_por_pagina;
      this.getProviderOffers();
    },
    async getProviderOffers() {
      let response = await this.services.AgreementMarco.getConvenioReview(
        this.agreement_detail.id,
        this.filters
      );

      if (response?.status === 200) {
        this.offer_providers = response?.data;
        this.filters.page = Number(response?.headers?.page);
        this.filters.per_page = Number(response?.headers?.per_page);
        this.filters.total_rows = Number(response?.headers?.total_rows);
      }
    },
    showRestElements() {
      const restos = this.filters.per_page * this.filters.page;
      if (restos <= this.filters.total_rows) return restos;
      else return restos - (restos - this.filters.total_rows);
    },
    firstElement(value) {
      return this.showRestElements() - value + 1;
    },
  },
  created() {
    if (Object.keys(this.agreement_detail).length != 0) {
      if (this.breadcrumbsItems.length > 1) {
        this.breadcrumbsItems.pop();
      }
      this.breadcrumbsItems.push({
        text: this.agreement_detail.correlativo,
      });
      this.getProviderOffers();
    } else {
      this.$router.push("/convenio-marco-list");
    }
  },
};
</script>
<style lang="scss" scoped>
:deep(.theme--light.v-tabs-items) {
  background: transparent !important;
}
</style>
